<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  <div>
    <div class="container">
      <div class="justify-content-center align-items-center">
        <div class="row" v-if="isAdmin">
          <span class="text-muted mb-2 mt-5">Masukkan ID FPL kamu pada form dibawah</span>
          <div class="col-lg-10 mb-2">
            <input type="number" name="idFpl" id="idFpl" class="form-control" placeholder="ID FPL" v-model="id" />
          </div>
          <div class="col-lg-2">
            <button class="btn btn-primary form-control" @click="participantDetail(id)" :class="{ disabled: isLoading }">
              Register
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="text-danger mt-3 fw-bold">
              Peserta Failed : {{ failed }}
            </div>
            <div class="text-success mt-2 fw-bold">
              Peserta Success : {{ passed }}
            </div>
            <div class="text-black mt-2 fw-bold">
              Total Peserta : {{ passed + failed }}
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-12 mt-3">
            <table class="table table-striped table-borderless">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">ID</th>
                  <th scope="col">Name</th>
                  <th scope="col">Team</th>
                  <th scope="col">Country</th>
                  <th scope="col" class="text-center">Experiences</th>
                  <th scope="col">Last Rank</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody v-for="(item, index) in participants" :key="index">
                <tr :class="{ 'table-danger': item.status == 'Failed' }">
                  <th v-text="index + 1"></th>
                  <th v-text="item.id"></th>
                  <td v-text="item.name"></td>
                  <td v-text="item.team"></td>
                  <td v-text="item.country"></td>
                  <td v-text="item.experiences" class="text-center"></td>
                  <td v-text="item.rank"></td>
                  <td v-text="item.status" class="fw-bold"
                    :class="{ 'text-danger': item.status == 'Failed', 'text-success': item.status == 'Success', 'text-warning': item.status == 'Pending' }">
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row" v-show="page <= 6">
          <div class="col-lg-12 mb-2">
            <button type="button" class="btn btn-primary" @click="loadMoreData(page)">
              Load more
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import axios from 'axios'
import { ids, ids_page2, ids_page3, ids_page4, ids_page5, ids_page6 } from "./data/ids.js";
export default {
  name: 'App',
  data() {
    return {
      id: '',
      rank: 0,
      participant: {
        id: '',
        name: '',
        team: '',
        country: '',
        experiences: '0',
        rank: '',
        status: ''
      },
      participants: [],
      ids: ids,
      ids_page2: ids_page2,
      ids_page3: ids_page3,
      ids_page4: ids_page4,
      ids_page5: ids_page5,
      ids_page6: ids_page6,
      isLoading: false,
      isEligible: false,
      failed: 0,
      passed: 0,
      page: 2,
      isAdmin: false
    }
  },
  methods: {
    async participantDetail(id) {
      this.isLoading = true
      axios.get(`https://fpljones.pages.dev/api/entry/${id}`)
        .then((response) => {
          //console.log(response.data)
          this.participantVerif(response.data)
        })
        .catch(error => {
          this.showAlert('error',error,'error')
        })
    },
    async participantVerif(data) {
      axios.get(`https://fpljones.pages.dev/api/entry/${data.id}/history/`)
        .then((response) => {
          if (response.status == 200) {
            let result = response.data
            let ranks = result.past
            const participant = {
              id: data.id,
              name: `${data.player_first_name} ${data.player_last_name}`,
              country: data.player_region_name,
              experiences: `${data.years_active} Years`,
              team: data.name,
              rank: 'Unknown',
              status: 'Pending',
            }
            if (ranks.length == 0) {
              participant.status = 'Failed'
              participant.rank = '0'
              this.isEligible = false
            } else {
              this.rank = ranks[ranks.length - 1].rank
              let rankFormat = Intl.NumberFormat().format(this.rank)
              if (this.rank >= 1000000) {
                participant.status = 'Success'
                participant.rank = rankFormat
                this.isEligible = true
              } else {
                participant.status = 'Failed'
                participant.rank = rankFormat
                this.isEligible = false
              }
            }
            this.participants.push(participant)
            const failedCount = this.participants.filter((item) => item.status == "Failed")
            const passedCount = this.participants.filter((item) => item.status == "Success")
            this.failed = failedCount.length
            this.passed = passedCount.length
          } 
        }).catch(error => {
          this.showAlert('error', error, 'error')
        })
    },
    loadMoreData(page) {
      switch (page) {
        case 2:
          this.ids_page2.forEach(item => {
            this.participantDetail(item)
          });
          this.page = page + 1
          break;
        case 3:
          this.ids_page3.forEach(item => {
            this.participantDetail(item)
          });
          this.page = page + 1
          break;
        case 4:
          this.ids_page4.forEach(item => {
            this.participantDetail(item)
          });
          this.page = page + 1
          break;
        case 5:
          this.ids_page5.forEach(item => {
            this.participantDetail(item)
          });
          this.page = page + 1
          break;
        case 6:
          this.ids_page6.forEach(item => {
            this.participantDetail(item)
          });
          this.page = page + 1
          break;
        default:

          break;
      }
    },
    showAlert(title, message, icon) {
      // const Swal = SweetAlert;
      // Swal.fire({
      //   title: title,
      //   text: message,
      //   icon: icon,
      //   confirmButtonText: 'Close'
      // })
      console.log(title+message+icon)
    },
  },
  mounted() {
    this.ids.forEach(item => {
      this.participantDetail(item)
    });
  }
  // components: {
  //   HelloWorld
  // }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
