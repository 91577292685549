const ids = [
    160277,
    761378,
    480164,
    4630,
    751674,
    452696,
    1033356,
    286573,
    216743,
    303254,
    1584434,
    955684,
    160512,
    414056,
    249534,
    771209,
    776103,
    97900,
    1084388,
    495166 
    ]

const ids_page2 = [
    2314735,
    19721,
    2881051,
    215198,
    2880344,
    738037,
    447397,
    744518,
    217113,
    1155403,
    755859,
    756235,
    346839,
    1633578,
    2817666,
    1805370,
    40237,
    712524,
    40019,
    1185872]

const ids_page3 = [
    230117,
    122039,
    1422106,
    104507,
    227339,
    1351212,
    255104,
    84265,
    2689720,
    1868106,
    328556,
    225396,
    2515180,
    24665,
    2895584,
    148553,
    1888449,
    226759,
    2898829,
    99279]

const ids_page4 = [
    240890,
    2078174,
    837181,
    65876,
    1573045,
    46119,
    2690340,
    79693,
    2877192,
    293806,
    694694,
    2781185,
    1951121,
    51165,
    407636,
    139692,
    772051,
    672602,
    2927094,
    734799]

const ids_page5 = [
    2449597,
    934870,
    271272,
    90689,
    246346,
    56795,
    1694116,
    2625417,
    2708027,
    354503,
    434696,
    1240161,
    484043,
    1011124,
    1953442,
    1579064,
    317458,
    735862,
    694487,
    84910]

const ids_page6 = [
    231743,
    2042224,
    735933,
    748599,
    53482,
    1955466]

    export {
        ids,
        ids_page2,
        ids_page3,
        ids_page4,
        ids_page5,
        ids_page6
    }